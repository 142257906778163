import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";

import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesBA,
  useStylesCAB,
  useStylesContainers,
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { stElemnt } from "../../../../St/comps/cStyle";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

import clsx from "clsx";
import { insh as inshst } from "../../../../Context/InshSt";

import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { insh, sto } from "../../../../Context/InshData";

import toast from "react-hot-toast";

import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { reFormatDate } from "../../../../utils/utils";
import { dfaultfilters } from "../../../../services/taswya-servece";
import SafesTaswyaTable from "./SafesTaswyaTable";
import SafesTaswyaHead from "./SafesTaswyaHead";
import SafesTaswyaForm from "./SafesTaswyaForm";
import SafesTaswyaAppBar from "./SafesTaswyaAppBar";
import SafesTaswyaSearch from "./SafesTaswyaSearch/SafesTaswyaSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function SafesTaswya(props) {
  const { AutoOptions, isEstelam } = props;

  const typeTaswya = [
    { id: 1, name: "سعر الشراء" },
    { id: 2, name: "سعر البيع" },
  ];
  const [SafesOptions, setSafesOptions] = useState(() => {
    return [{ id: -1, name: "الكل" }, ...AutoOptions.Safes];
});
let OpteinsUser
const [UsersOptions, setUsersOptions] = useState(() => {
    return [AutoOptions?.Users];
});

  const defaultTransvalue = {
    id: '',
    no: AutoOptions.other.lastid,
    date: moment(new Date()).format('YYYY-MM-DD'),
    safe: isEstelam ? '' : AutoOptions.Safes[0].id,
    center: -1,
    refno: '',
    notes: '',
    res_id:'',
    is_deleted:'',
    acc:AutoOptions.MedAccount[0].id,
    branch: sto.branchi,
    Type_Price:typeTaswya[0].id,
    create_user:'',
    update_user: sto.empi,
    create_date:'',
    update_date:'',
    total_print:'',
    total_update:'',
    create_computer:'',
    update_computer:'',
  };
  const transsubvalue = {
    tsweya_id: '',
    item: '',
    unit: 1,
    avg_price: '',
    val1:0,
    val2:0,
    diff:0,
    difftot:0,
    sub_branch: sto.branchi,
    sub_center: -1,
    Price: 0,
    SumPrice: 1,
    cost_curr:0,
    qty_balance: 0,
    cost_curr: 0,
    unitName: '',
    symbol: '',
    barcode: '',
    perc: 1,
    salePrice: 1,
    purchPrice: 1,
    unit_balance:0,
    avgCost:0,
  };
  const defaultvalueAutos = {
    safe: AutoOptions.Safes[0],
    branch: { id: AutoOptions.Branches[0].id, name: AutoOptions.Branches[0].name },
    center: { id: -1, name: 'الكل' },
    acc: { id:  AutoOptions.MedAccount[0].id, name: AutoOptions.MedAccount[0].name},
    Type_Price: typeTaswya[0],
  };
  const defaultvalueAutosSub = {
    curr: AutoOptions.items[0],
    // units:{unit :-1 ,unitName:"الوحدة", perc:1, sale:0, purch:0},
    units: { unit: -1, unitName: "الوحدة", perc: 1, sale: 0, purch: 0, curId: 1 },
  };
  const codevalues = {
    id: 0,
    code: '',
  };
  const [rptParams, setRptParams] = React.useState({
    ...defaultTransvalue, no: AutoOptions.other.lastid,
   safe:  AutoOptions.Safes[0].id , branch: AutoOptions.Branches[0].id,
  });
  const [valueAutos, setValueAutos] = React.useState(defaultvalueAutos);
  const [valueAutosSub, setValueAutosSub] = useState(defaultvalueAutosSub);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [estelamAfter, setEstelamAfter] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openSearchMdl, setOpenSearchMdl] = useState(false);

  const onFilterByClick = () => {
    setIsOpen(true);
  };


  const [paramsSubList, setParamsSubList] = useState([]);
  const [dataPrintList, setDataPrintList] = useState([]);

  const [unitsListTable, setUnitsSubListTable] = useState([]);

  const [selectItems, setSelectItems] = useState(true);



  const [rptParamsSub, setRptParamsSub] = useState(transsubvalue);
  const [rptCode, setRptCode] = useState(codevalues);


  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [editing, setEditing] = useState(false);

  const [sum, setSum] = useState(false);

  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const clasCAB = useStylesCAB();

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const his = useHistory();
  const [filters, setFilters] = useState(dfaultfilters);

  const getReFormatingGtys=(data)=>{

    const newData = data.map((row, i)=> {
            return {...row, id:i,
                 item:row.currency_from,
                 symbol: row.Name_Item,
                 barcode:row.barcode,
                 val1:parseFloat(row.Qtys),
                val2:0, 
            diff: (0 - row.Qtys),
            difftot: (0 - row.Qtys),
            Price:row.avgCost,
            SumPrice:(parseFloat(row.avgCost)*(0 - row.Qtys)).toFixed(2),
            cost_curr:row.avgCost,
            purchPrice:row.avgCost,
            sub_branch: sto.branchi,
            unit:row.unit,
            unitName:row.mUnit?.name,
          } 
        })
    return newData
}

  const GetByFilters = async (filter) => {
    setFetching(true);
    await axios
      .get(
        uri +
          `TaswyaBasicData/TaswyaGtys?typeQtys=${filter?.filter?.id}
    &&safe=${rptParams?.safe}
    &&branch=${rptParams?.branch}
    &&group_id=${filter?.group.id}`
      )
      .then((res) => {
        setParamsSubList(getReFormatingGtys(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    setFilters(filter);
    setFetching(false);
  };


  const [uiElement, setUiElement] = useState({});



  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const onChangeBarcodeSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    getBarcodeMulti(rptParamsSub.barcode, rptParams.safe,rptParamsSub.currency);
  };

  const getBarcodeMulti = async (barcode, safe) => {

    let item = null;
    let unitF = null;
    let ibance = null;
    let avgCost=null;


    if (barcode != null || safe != -1 || safe != null) {
      await axios
        .get(
          uri +
          `RptSafes/CurrCodesUnitsSub?currCode=${barcode}
        &&emp=${sto.empi}
        &&safe=${safe}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
          unitF = res.data?.unitsSub[0];
          ibance = res.data?.balance[0];
          setUnitsOptions(res.data?.unitsSub);
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null) {
        setSelectItems(false);
        if (ibance!=null){
            await barcodeadd(item, unitF, ibance);
        }else{
            await barcodeadd(item, unitF, 0);
        }
      } else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }

  };



  const barcodeadd = (dataCode, unitF, ibance) => {

    let percc = dataCode.perc;
    let prc = valueAutos.Type_Price.id == 2 ? parseFloat(dataCode.sale) : parseFloat(dataCode.purch);
    let qunntity=0;
    if (ibance.Qtys==null){
        qunntity=0;
    }else{
        qunntity=ibance.Qtys;

    }
    let untBlnce=(parseFloat(qunntity) / parseFloat(dataCode.perc)).toFixed(2);
    let difff=0-parseFloat(untBlnce);
    setRptParamsSub({
      ...rptParamsSub,
      val1: parseFloat(untBlnce),
      val2:0,
      diff:0-parseFloat(untBlnce),
      difftot:0-parseFloat(qunntity),
      item: dataCode.curr,
      barcode: dataCode.barcode,
      symbol: dataCode.symbol,
      qty_balance: parseFloat(qunntity),
      perc: dataCode.perc,
      Price: rptParams.Type_Price == 2 ? parseFloat(dataCode.sale) : parseFloat(dataCode.purch),
      SumPrice: rptParams.Type_Price== 2 ? parseFloat(dataCode.sale *difff) : parseFloat(dataCode.purch *difff),
      salePrice: parseFloat(dataCode.sale),
      purchPrice: parseFloat(dataCode.purch),
      cost_curr: parseFloat(dataCode.purch),
      total: ((prc * 1)).toFixed(2),
      value: ((parseFloat(dataCode.perc) * 1)).toFixed(2),
      unit: dataCode.unit,
      unitName: dataCode.unitName,
      unit_balance:qunntity,
      avg_price:ibance==0?parseFloat(dataCode.purch):parseFloat(ibance.avgCost),
    });
    let res = { unitsSub: {}, itemSub: {} };

    AutoOptions.items.map((x) => {
      if (x.id == dataCode.curr) res.itemSub = x;
    });

    setValueAutosSub({
      curr: res.itemSub,
      units: unitF,
    });
    setUnitsSubListTable([...unitsListTable, unitsOptions]);

  };
  const selectRow = (row) => {
    setEditing(true);
    setRptParamsSub({ ...row });
    
    let res = {units:{},curr:{}};
    AutoOptions.items.map((x) => {
      if (x.id == row.item) res.curr = x;
    });
 
    setValueAutosSub({...defaultvalueAutosSub,
      curr:res.curr,
       units: { unit:row.unit, unitName:row.unitName, perc: 1, sale: row.salePrice, purch:row. cost_curr, curId: row.item },
    })
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let pric=0;
    let tot=(parseFloat(rptParamsSub.Price)*parseFloat(rptParamsSub.val1)).toFixed(2);
    let val=(parseFloat(rptParamsSub.perc)*parseFloat(rptParamsSub.val1)).toFixed(2);
    setRptParamsSub({
      ...rptParamsSub,total:tot,value:val,
    });
    var oldData1 = paramsSubList.filter((obj) => obj.item == rptParamsSub.item );
    console.log("the oldadata is", oldData1);


    if (oldData1.length != 0) {
        if (editing){
            setEditing(false);
            const dataUpdate = [...paramsSubList];
            // const index = oldData1.item;
            const index = oldData1.id;

            dataUpdate[index] = rptParamsSub;
            setParamsSubList([...dataUpdate]);
            setRptParamsSub(transsubvalue);
        }else{
            alert("لا يمكن تنزيل نفس الصنف في القائمة");
        }
      //  handSums(musadata); 
    } else {

      setParamsSubList([...paramsSubList, rptParamsSub]);
      setSelectItems(true);
      setRptParamsSub(transsubvalue);
    }

  };

  const onChangeDataSub = (propName) => (event) => {
    setRptParamsSub({
      ...rptParamsSub, [propName]: event.target.value,
    });
  };

  const onChangeDataSubQ = (propName) => (event) => {
   
    setRptParamsSub({
      ...rptParamsSub, [propName]:event.target.value,
    });
  };
  const MathChangIdata = (prop, val) => {
    let i = {
      prc: Number(rptParamsSub.Price),
      val1: Number(rptParamsSub.val1),
      valu: Number(rptParamsSub.val2),
      tot: Number(rptParamsSub.SumPrice),
      sal: Number(rptParamsSub.salePrice),
      perc: Number(rptParamsSub.perc),
      prch: Number(rptParamsSub.purchPrice),
      diff: Number(rptParamsSub.diff),
      difftot: Number(rptParamsSub.difftot),
      sumtot: Number(0),
      sumval: Number(0),
      salv: Number(0),
      prchv: Number(0),
      res: Number(val),
    };
   
    if (prop === "Price") {
        i.tot = i.res *i.diff;
        i.salv=valueAutos.Type_Price.id==2?i.prc:i.sal;
        i.prchv=valueAutos.Type_Price.id==1?i.prc:i.prch;
    }
    else if (prop === "val2") {
      i.sumtot = i.res *i.prc;
        i.sumval=i.res*i.perc;

        i.diff=i.res-i.val1;

        i.difftot=(i.res-i.val1)*i.perc;
        i.tot=i.diff*i.prc;
        i.salv=i.sal;
        i.prchv=i.prch;

    }
    setRptParamsSub({
        ...rptParamsSub,
        [prop]: i.res,
        SumPrice: i.sumtot,
        diff:parseFloat(i.diff).toFixed(2),
        difftot:parseFloat(i.difftot).toFixed(2),
        SumPrice:parseFloat(i.tot).toFixed(2),
        salePrice:i.salv,
        purchPrice:i.prchv,
      });
    
  };
  const onChangeDataSubP = (prop) => (event) => {
    MathChangIdata(prop,event.target.value);
    // let valall = 0;
    // if (event.target.value==null)event.target.value=0;
    // valall = (parseFloat(event.target.value) * parseFloat(rptParamsSub.val1));

    // let prcname = rptParams.Type_Cost == 2 ? "salePrice" : "purchPrice";
   
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({
      ...rptParams, [propName]: event.target.value, Type_Price: valueAutos.Type_Price.id
    });
  };

  const onChangeAutoData = (propName, newValue) => {
    setValueAutos({ ...valueAutos, [propName]: newValue });
    setRptParams({ ...rptParams, [propName]: newValue.id });
  };
  const onChangeAutoCost = (propName, newValue) => {
    setValueAutos({ ...valueAutos, [propName]: newValue });
    setRptParams({ ...rptParams, Type_Price: newValue.id });

    // if (paramsSubList.length > 0) {
    //   setParamsSubList(

    //     paramsSubList?.map((x) => {
    //       let prc_sal = 0.0;
    //       prc_sal = parseFloat(x.salePrice);
    //       let prc_pur = 0.0;
    //       prc_pur = parseFloat(x.purchPrice);

    //       let allsale = 0.0;
    //       let allpurch = 0.0;
    //       allsale = (parseFloat(x.salePrice) * parseFloat(x.val1)).toFixed(2);
    //       allpurch = (parseFloat(x.purchPrice) * parseFloat(x.val1)).toFixed(2);
    //       let prc_1 = 0.0;
    //       let tot_1 = 0.0;

    //       prc_1 = newValue.id == 2 ? parseFloat(prc_sal).toFixed(2) : parseFloat(prc_pur).toFixed(2);
    //       tot_1 = newValue.id == 2 ? parseFloat(allsale).toFixed(2) : parseFloat(allpurch).toFixed(2);
    //       return {
    //         ...x,
    //         price: prc_1,
    //         total: tot_1,
    //       }
    //     }));
    // }
    // if (rptParamsSub.barcode != null) {
    //   let tot = 0.0;
    //   let prc = 0.0;
    //   prc = newValue.id == 2 ? rptParamsSub.salePrice : rptParamsSub.purchPrice;
    //   tot = (parseFloat(prc) * parseFloat(rptParamsSub.val1)).toFixed(2);
    //   setRptParamsSub({ ...rptParamsSub, total: parseFloat(tot), price: parseFloat(prc) });
    // }
  };

  const onChangeAutoDataSubunit = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    let quantot = 0.0;
    quantot = (  parseFloat(rptParamsSub.qty_balance)/parseFloat(newValue.perc)).toFixed(2);
    let deff = 0.0;
    deff =   (parseFloat(rptParamsSub.val2)-parseFloat(quantot)).toFixed(2);
    let defftot = 0.0;
    defftot = (parseFloat(deff)  *parseFloat(newValue.perc)).toFixed(2);

    let prc = 0.0;
    prc = valueAutos.Type_Price.id == 2 ? parseFloat(newValue.sale) : parseFloat(newValue.purch);

    let sumPrice = 0.0;
    sumPrice = (parseFloat(deff) * parseFloat(prc)).toFixed(2);

    setRptParamsSub({
      ...rptParamsSub, 
      unit: newValue.unit,
      perc: newValue.perc,
      Price: valueAutos.Type_Price.id == 2 ? newValue.sale : newValue.purch,
      val1: quantot, 
      diff:deff,
      difftot:defftot,
      cost_curr: newValue.purch, 
      SumPrice: sumPrice,
      purchPrice: newValue.purch, 
      salePrice: newValue.sale,
      unitName:newValue.unitName,
    });
  };

  const onChangeAutoDataSubCurr = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    setRptParamsSub({ ...rptParamsSub, currency: newValue.id, symbol: newValue.name, barcode: newValue.barcode });
    getBarcodeMulti(newValue.barcode, rptParams.safe_from,newValue.id);
  };


  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => {
    his.goBack();

  };
  const cls = useRptAF();


  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);

  const clasBA = useStylesBA();
  const [transition, setTransition] = React.useState(undefined);

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });
  const ButtonWitting = (prop) => {
    reloadGets();

  };
  const handNewItem = () => {
    reloadGets();
    //const defaultTG = AutoOptions.tax_groups?.filter((t) => t.isDefault == true);

    setPrevent(insh.prevents);

    setParamsSubList([]);
    setRptParamsSub(transsubvalue);
    setUnitsOptions([]);
    setSelectItems(false);
    setValueAutosSub(defaultvalueAutosSub);
    setValueAutos(defaultvalueAutos);
    setEstelamAfter(false);
  };
  const timer = React.useRef();

  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop === "print") {printHandle();}
      else if (prop === "save"){ handNewItem();}
     else if (prop === "delete") {handNewItem();}
     else{
      handNewItem();
     }
      setPrevent(true);
    }, 2000);
  };
  const printHandle = () => {
      
    // if (paramsSubList== 0) {
    //   alert("لاتوجد بيانات");
    // } else {
    //   const info = {
    //     data: paramsSubList,
    //     tot: {
    //       count:paramsSubList.length,
    //       totQun:paramsSubList
    //       .reduce((a, v) => (a = a + parseFloat(v.val1)), 0)
    //       .toFixed(2),
    //       totCost:paramsSubList
    //       .reduce((a, v) => (a = a + parseFloat(v.value)), 0)
    //       .toFixed(2)
    //     },
    //     title:  "أمر تحويل مخزني ",
    //     date:rptParams.date ,
    //     transNo:rptParams.no,
    //     nDate: moment(new Date()).format("YYYY-MM-DD"),
    //     branch: valueAutos.branch.name,
    //     safe: valueAutos.safe.name,
    //   };
    //   localStorage.setItem("iInfos", JSON.stringify(info));
    //   his.push({ pathname: "/ReviewTransfer" });
    //   window.location.reload(false);
    // }
  };


  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", 1000);
    //handNewItem();
  };
  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });


  const addChangds = () => {
    rptParams.date =
      moment(rptParams.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");

      rptParams.branch=valueAutos.safe.branch;
  }
  const [openDw, setOpenDw] = useState(false);

  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenDw(true);
  };
  const handCloseDw = () => {
    setOpenDw(false);
  };
  const getSearchHeadData = (sTransferData,subData) => {
    setParamsSubList(
      subData.map((sub) => {
        let blncunit=(parseFloat(sub.qty_balance)/parseFloat(sub.perc)).toFixed(2);
        return {
          ...sub,
          currency: sub.currency,
          barcode: sub.barcode,
          symbol: sub.itemName,
          unit: sub.unit,
          unitName: sub.unitName,
          val1: sub.val1,
          total: sub.total,
          value: sub.value,
          Price: sub.Price,
          cost_curr: sub.cost_curr,
          SumPrice:(parseFloat( sub?.Price)*parseFloat(sub.diff)).toFixed(2),
          salePrice: sTransferData.Type_Price == 2 ? sub.Price : 0,
          purchPrice: sTransferData.Type_Price == 2 ? 0 : sub.Price,
          qty_balance: sub.qty_balance,
          unit_balance:blncunit,
          purchPrice:sub.cost_curr,
        };
      }));
    setUnitsSubListTable(
      subData.map((sub) => {
        return {
          curId: sub.currency,
          unit: sub.unit,
          unitName: sub.unitName,
          sale: sTransferData.Type_Price == 2 ? sub.price : 0,
          purch: sTransferData.Type_Price == 2 ? 0 : sub.price,
          perc: sub.perc,
        };
      }));
    setRptParams({...rptParams,
      no:sTransferData.no,
      acc:sTransferData.acc,
      safe:sTransferData.safe,
      center:sTransferData.center,
      no:sTransferData.no,
      id:sTransferData.id,
      refno:sTransferData.refno,
      branch:sTransferData.branch,
      Type_Price:sTransferData.Type_Price,
      notes:sTransferData.notes,
      date:reFormatDate(sTransferData.date),
      res_id:sTransferData.res_id,
    });

    let res = {acc:{},safe:{},centers:{},typesCost:{}};
    AutoOptions.Safes.map((x) => {
      if (x.id == sTransferData.safe) res.safe = x;
    });
    AutoOptions.Accindex.map((x) => {
      if (x.id == sTransferData.acc) res.acc = x;
    });
    typeTaswya.map((x) => {
      if (x.id == sTransferData.Type_Price) res.typesCost = x;
    });
    AutoOptions.center.map((x) => {
      if (x.id == sTransferData.center) res.centers = x;
    });

    setValueAutos({...valueAutos,
      safe:res.safe,
      acc:res.acc,
      center:res.centers,
      Type_Price:res.typesCost,
    })
  };

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });

  const addToBS = (props) => {
    if (paramsSubList.length > 0&&rptParams.safe !=="" && rptParams.safe !==null ) {
        if (rptParams.acc !=="" && rptParams.acc !==null  && rptParams.acc !==-1 ){
        addChangds();
        ButtonLoading("save");
        let transSub = paramsSubList?.map(x => {
          console.log("thre group is ", x);
          return {
            tsweya_id:rptParams.id,
            val1: x?.val1,
            val2: x?.val2,
            Price: x?.Price,
            SumPrice:(parseFloat( x?.Price)*parseFloat(x.diff)).toFixed(2),
            unit: x?.unit,
            item: x?.item,
            user_emp: x?.user_emp,
            diff:x.diff,
            difftot:x.difftot,
            avg_price:x.avgCost,
            sub_branch:rptParams.branch ,
            sub_center: x?.sub_center,
            cost_curr: x?.cost_curr,
            qty_balance: x?.qty_balance,
            transfered_val: x?.transfered_val,
          }
        })
        const model = {
            taswya: rptParams,
            _Data: transSub,
        };
        console.log("the curre data is ", model);
  
        prevent
          ? axios
            .post(uri + "Taswya", model)
            .then((res) => {
              if (res.data?.data!=null) {
                // alert("رقم الباردكود موجود مسبقا");
                setUnitsSubListTable([]);
                setParamsSubList([]);
                setUnitsOptions([]);
                ButtonSuccess(props, 1000);
              } else {
                setUnitsSubListTable([]);
                setParamsSubList([]);
                setUnitsOptions([]);
                ButtonSuccess(props, 1000);
              }
  
            })
            .catch((res) => {
              ButtonSuccess(props, 1000);
            })
  
          :
          axios
            .put(uri + "Taswya", model)
            .then((res) => {
              if (res.data?.data!=null) {
                setUnitsSubListTable([]);
                setParamsSubList([]);
                setUnitsOptions([]);
                ButtonSuccess(props, 1000);
              } else {
                setUnitsSubListTable([]);
                setParamsSubList([]);
                setUnitsOptions([]);
                ButtonSuccess(props, 1000);
              }
            })
            .catch((res) => {
              ButtonSuccess(props, 1000);            
            });
          }else{
            alert("تأكد من اختيار الحساب ");
  
          }
      } else {
        alert("تأكد من اكمال البيانات  ");
      }
  };

  const deleteToI = async (e) => {

   
    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    ButtonLoading("delete");

    await axios.delete(uri + `Taswya?id=${rptParams.id}`).then(res => {
      toast.success("تم الحذف بنجاح");
      ButtonSuccess("delete", 1000);

    }).catch(err => {
      console.log("eror", err)
      ButtonSuccess("delete", 1000);
      toast.error("not delete ")
    })
  };
  const reloadGets = async () => {
    const tb = {
      wait: true,
      other: true,
      // Items: true,
      parms: {
        iempid: sto.empi,
        tname:"tswya",
        branch:sto.branchi
        // takei: 1,
      },
    };

    await axios
      .post(uri + "GetD", tb)
      .then((res) => {
        setRptParams({
          ...defaultTransvalue,
          no: res.data.other.lastid,
          branch: AutoOptions.Branches[0].id,
          safe_from:isEstelam?'':AutoOptions.Safes[0].id,
          safe_to:isEstelam?AutoOptions.Safes[0].id:'',
        });
      })
      .catch((error) => {
      });
  };

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {"أمر  تسوية مخزون"}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.blue[50],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          {/* <SafesTransferFilter {...{ valueAutos, setValueAutos }} /> */}
 
          <SafesTaswyaHead
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              valueAutos,
              typeTaswya,
              onChangeAutoCost,
              isEstelam,
              MathChangIdata,
              isOpen,
              setIsOpen,
              openSearchMdl,
              setOpenSearchMdl,
              onFilterByClick,
              GetByFilters,
              setFilters,
              filters
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >

          </Grid>
        </Container>
        {/* <br /> */}
        <Container maxWidth={false} style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "15px" }}
        >
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}>
            <SafesTaswyaForm
              {...{
                AutoOptions,
                rptParamsSub,
                onChangeDataSub,
                onChangeAutoDataSubunit,
                valueAutosSub,
                selectItems,
                unitsOptions,
                onChangeBarcodeSearch,
                onChangeAutoDataSubCurr,
                onSubmit,
                onChangeDataSubQ,
                onChangeDataSubP,
                editing,
              }}
            />

          </Grid>
        </Container>
        {/* <br /> */}
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.blue[50]} />
              {!fetching && (
                <SafesTaswyaTable
                  {...{
                    paramsSubList,
                    rptParams,
                    valueAutosSub,
                    setValueAutosSub,
                    unitsOptions,
                    setParamsSubList,
                    estelamAfter,
                    selectRow,
                  }}
                />
              )}
            </Grid>
            {paramsSubList.length > 0 && (
              <Grid
                container
                lg={12}
                md={12}
                className={cls.rootBdydown}
                style={{
                  paddingBottom: 5, marginTop: "1rem",
                  backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular"
                }}
              >
                <Grid container xs={12} /*className={cls.rootchip}*/ style={{
                  paddingBottom: 5,
                  backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular",
                  border: "solid 1px",
                  padding: "0.3rem 0.1rem 0.3rem 0.1rem",
                  borderRadius: "0px 0px 5px 0px",
                }}>

                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {" عدد الأصناف"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList.length}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {"إجمالي الكميات"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList
                      .reduce((a, v) => (a = a + parseFloat(v.val1)), 0)
                      .toFixed(2)}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {"إجمالي المبلغ"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList
                      .reduce((a, v) => (a = a + parseFloat(v?.SumPrice)), 0)
                      .toFixed(2)}
                  </Grid>
                </Grid>

              </Grid>)}
          </Grid>

        </Container>
      </Typography>

      <SafesTaswyaAppBar  {...{
        rptParams,
        setBarcodeDialogOpen,
        buttonClassnameNew,
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        newToI,
        setting,
        stElemnt,
        loading,
        clasBA,
        backHome,
        back,
        uiElement,
        handOpenDw,
        TransitionUp,
        addToBS,
        setOpenDw,
        estelamAfter,
        deleteToI,
        printHandle,
        prevent,
      }} />
      <SafesTaswyaSearch {...{
        handOpenDw,
        TransitionUp,
        setPrevent,
        openDw,
        transition,
        handCloseDw,
        getSearchHeadData,
        setEstelamAfter,
        SafesOptions,
        UsersOptions
      }}
      />
    </div>
  );
}
