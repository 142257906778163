// sal شيك - شبكه

// rt
const headerB = {
  height: "20px",
  color: "#fff",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  backgroundColor: "#f18584",
  padding: "10px 0px",
  marginLeft: 1,
  marginRight: 1,
  // display:'none'
};

const headerS = {
  height: "20px",
  color: "#fff",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  backgroundColor: "#319835",
  padding: "15px 0px",
  marginLeft: 1,
  marginRight: 1,
  // display:'none'
};

const action = {
  header: {
    // // height:'20px',
    color: "#fff",
    textAlign: "-webkit-center",
    // margin:'0px',
    // whiteSpace: 'revert',
    // maxWidth:'0px',
    // position:'relative',
    // backgroundColor:'red',
    padding: "15px 1px",
    // display: 'flex',

    // marginLeft: 0, marginRight: 0,
    // backgroundColor:'#20369f',
    // display: 'flow-root'
  },
  cell: {
    width: "0px",
    textAlign: "-webkit-center",
    padding: "5px 2px",
    margin: "0px",
    whiteSpace: "nowrap",
    // color:'#000',
    // overflow: 'hidden',
    // backgroundColor:'orange',
    // border:'solid 0.1px green'
  },
};

const cellS = {
  padding: "5px 0px",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  // border:'solid 0.1px orange',
  // backgroundColor:'#ddd',
  // width:'0px',
  // color:'#000',
  // marginLeft: 2, marginRight: 2,
};

const mt = {
  note: {
    padding: "6.9px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    // whiteSpace: "nowrap",
    width: "200px",
    color: "#000",
    fontSize: "0.7rem",
    // border:'solid 1px red'
    marginLeft: 2.5,
    marginRight: 2.5,
  },
};

const stElemnt = {
  all: {
    appBarBA: { top: "auto", bottom: 0 },
    fabButtonBA: {
      position: "absolute",
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: "0 auto",
    },
    iconBtn: {
      boxShadow:
        "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    },
  },

  itemHigh: { top: "-40px" },
  buttonBCS: {
    padding: 0,
    borderRadius: 50,
    height: 39,
    width: 64,
    // margin: 50
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 18%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },

  //  ========== B Header MT ==========
  InvsH: {
    height: "20px",
    color: "#fff",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "nowrap",
    width: "0px",
    backgroundColor: "#20369f",
    padding: "15px 2px",
    marginLeft: 1,
    marginRight: 1,
  },
  InvsR: {
    padding: "1rem 0rem",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "nowrap",
    width: "0px",
    color: "#000",
    // border:'solid 1px red'
    marginLeft: 1,
    marginRight: 1,
  },
  SubH: {
    backgroundColor: "#4e9e84",
    // backgroundImage: 'linear-gradient(to right, #4e9e84 , #2688ba)',
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 10,
    borderLeft: "solid 0.1px green",
    paddingTop: 1,
    paddingBottom: 1,
  },
  SubHMu: {
    // backgroundColor: "#4e9e84",
    // color: "#fff",
    // textAlign: "center",
    // whiteSpace: "nowrap",
    // fontSize: 14,
    // borderLeft: "solid 0.1px green",
    // paddingTop: 2,
    // paddingBottom: 2,


    backgroundColor: "#4e9e84",
    // backgroundImage: 'linear-gradient(to right, #4e9e84 , #2688ba)',
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 12,
    borderLeft: "solid 0.1px green",
    paddingTop: 1,
    paddingBottom: 1,
  },
  SubHPruch: {
    // backgroundColor: "#008080",
    // color: "#fff",
    // textAlign: "center",
    // whiteSpace: "nowrap",
    // fontSize: 14,
    // borderLeft: "solid 0.1px blue",
    // paddingTop: 2,
    // paddingBottom: 2,


    backgroundColor: "#008080",
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 12,
    borderLeft: "solid 0.1px blue",
    paddingTop: 1,
    paddingBottom: 1,
  },
  SubHSafes: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 12,
    borderLeft: "solid 0.1px blue",
    paddingTop: 1,
    paddingBottom: 1,
    
  },
  SubHMake: {
    backgroundColor: "#c74375",
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 12,
    borderLeft: "solid 0.1px blue",
    paddingTop: 1,
    paddingBottom: 1,
    
  },
  SubHRest: {
    backgroundColor: "#eee",
    // backgroundImage: 'linear-gradient(to right, #4e9e84 , #2688ba)',
    color: "#000",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: 14,
    // borderLeft: "solid 0.1px green",
    paddingTop: 4,
    paddingBottom: 2,
  },
  SubR2: {
    // backgroundColor: '#329936',
    textAlign: "center",
    // color:'#fff',
    whiteSpace: "nowrap",
    fontSize: 14,
    // borderLeft:'solid 0.1px green',
    paddingTop: 0,
    paddingBottom: 0,
  },
  SubR1: {
    // backgroundColor: '#329936',
    textAlign: "center",
    // color:'#fff',
    whiteSpace: "nowrap",
    fontSize: 14,
    // borderLeft:'solid 0.1px green',
    paddingTop: 0,
    paddingBottom: 0,
  },
  SubR :{
 
    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    BorderBottom: "solid 0.5px",
    fontSize: 12,
    width: "300px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
},
SubMu :{
 
  padding: "0px 0px",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "normal",
  BorderBottom: "solid 0.5px",
  fontSize: 10,
  minWidth:"500px",
  width: "500px",
  // minWidth:"300px",
  fontFamily: "Amiri-Regular",
  fontWeight:"bold",
},
  CurrH: {
    height: "15px",
    color: "#fff",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "nowrap",
    width: "0px",
    backgroundColor: "#20369f",
    padding: "15px 2px",
    marginLeft: 1,
    marginRight: 1,
  },
  CH: {
    backgroundColor: "#20369f",
    padding: "10px 2px",
    marginLeft: 1,
    marginRight: 1,
  },
  CurR: {
    padding: "1rem 0rem",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "nowrap",
    width: "0px",
    color: "#000",
    // border:'solid 1px red'
    marginLeft: 1,
    marginRight: 1,
  },

  /* b now useing */
  mt: {
    header: {
      color: "#fff",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      backgroundColor: "#20369f",
    },
    cell: {
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      // border:'solid 0.1px orange',
      // backgroundColor:'#ddd',
      // width:'0px',
      // color:'#000',
      // marginLeft: 2, marginRight: 2,
    },
    Hpoint: [
      {
        height: "15px",
        padding: "15px 2px",
        marginLeft: 1,
        marginRight: 1,
      },
      {
        height: "15px",
        padding: "15px 2px",
        marginLeft: 1,
        marginRight: 1,
      },
    ],
    Cpoint: [
      {
        padding: "5px 0px",
      },
      {
        padding: "5px 0px",
      },
    ],
  },
  /* e now useing */
  //  {textAlign: "center", whiteSpace: "nowrap",fontSize: 10,padding:0,},

  mtHInvs: "", //  {backgroundColor: 'orange',textAlign: "center", whiteSpace: "nowrap",fontSize: 10,borderLeft:'solid 0.5px green',paddingTop:2,paddingBottom:2,},

  // mtHSubPurch:{},
  // mtHSubSafe:{},

  mtHCurrSal:
    //  {backgroundColor:'#4fa15236',textAlign:"center",whiteSpace:"nowrap",fontSize:11,paddingTop:2.5,paddingBottom:2.5,borderRadius:20},
    {
      backgroundColor: "#daebda",
      textAlign: "center",
      whiteSpace: "nowrap",
      fontSize: 11,
      padding: (0, 2.5, 0, 2.5),
      borderRadius: 20,
    },
};

export { mt, action, cellS, headerS, stElemnt, headerB };
