import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";

import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesBA,
  useStylesCAB,
  useStylesContainers,
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { stElemnt } from "../../../../St/comps/cStyle";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

import clsx from "clsx";
import { insh as inshst } from "../../../../Context/InshSt";

import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { insh, sto } from "../../../../Context/InshData";

import deepRenameKeys from "deep-rename-keys";
import SafesTranferHead from "./SafesTransferHead";
import SafesTransfersForm from "./SafesTransfersForm";
import SafesTransferTables from "./SafesTransferTable";
import toast from "react-hot-toast";
import { selectTransferType } from "../../../../services/saleAnalysis-services";
import SafesTransferFilter from "./SafesTransferFilter";
import { perc } from "react-grid-layout/build/utils";
import SalesTransferBar from "./SafesTransferBar";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import TransfersSearch from "./SafesTransferSearch/TransfersSearch";
import { reFormatDate } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function SafesTransfer(props) {
  const { AutoOptions, isEstelam } = props;

  const typeTransfer = [
    { id: 0, name: "كميات فقط" },
    { id: 1, name: "سعر الشراء" },
    { id: 2, name: "سعر البيع" },
  ];
  const defaultTransvalue = {
    id: '',
    transfer_no: '',
    no: AutoOptions.other.lastid,
    branch_create: sto.branchi,
    refno: '',
    safe_from: isEstelam ? '' : AutoOptions.Safes[0].id,
    safe_to: isEstelam ? AutoOptions.Safes[0].id : '',
    branch: sto.branchi,
    date: moment(new Date()).format('YYYY-MM-DD'),
    center: -1,
    notes: '',
    IS_Deleted: false,
    Type: isEstelam == true ? 2 : 1,
    Type_Cost: typeTransfer[0].id,
  };
  const transsubvalue = {
    transfer_id: '',
    currency: '',
    val1: 0,
    unit: 1,
    transfered_val: null,
    user_emp: sto.empi,
    sub_branch: sto.branchi,
    sub_center: -1,
    value: 0,
    price: 0,
    total: 1,
    qty_balance: 0,
    cost_curr: 0,
    unitName: '',
    symbol: '',
    barcode: '',
    perc: 1,
    salePrice: 1,
    purchPrice: 1,
    unit_balance:0,
  };
  const defaultvalueAutos = {
    safe_from: isEstelam ? { id: '', name: '', branch: -1 } : AutoOptions.Safes[0],
    safe_to: isEstelam ? AutoOptions.Safes[0] : { id: '', name: '', branch: -1 },
    branch: { id: AutoOptions.Branches[0].id, name: AutoOptions.Branches[0].name },
    center: { id: -1, name: 'الكل' },
    alowEstlam: selectTransferType[0],
    Type_Cost: typeTransfer[0],
  };
  const defaultvalueAutosSub = {
    curr: AutoOptions.items[0],
    // units:{unit :-1 ,unitName:"الوحدة", perc:1, sale:0, purch:0},
    units: { unit: -1, unitName: "الوحدة", perc: 1, sale: 0, purch: 0, curId: 1 },
  };
  const codevalues = {
    id: 0,
    code: '',
  };
  const [rptParams, setRptParams] = React.useState({
    ...defaultTransvalue, no: AutoOptions.other.lastid,safe_from:isEstelam ? '' : AutoOptions.Safes[0].id,
   safe_to: isEstelam ? AutoOptions.Safes[0].id : '', branch: AutoOptions.Branches[0].id,
  });
  const [valueAutos, setValueAutos] = React.useState(defaultvalueAutos);
  const [valueAutosSub, setValueAutosSub] = useState(defaultvalueAutosSub);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [estelamAfter, setEstelamAfter] = useState(false);



  const [paramsSubList, setParamsSubList] = useState([]);
  const [dataPrintList, setDataPrintList] = useState([]);

  const [unitsListTable, setUnitsSubListTable] = useState([]);

  const [selectItems, setSelectItems] = useState(true);



  const [rptParamsSub, setRptParamsSub] = useState(transsubvalue);
  const [rptCode, setRptCode] = useState(codevalues);


  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);

  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const clasCAB = useStylesCAB();

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const his = useHistory();



  const [uiElement, setUiElement] = useState({});



  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const onChangeBarcodeSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    getBarcodeMulti(rptParamsSub.barcode, rptParams.safe_from,rptParamsSub.currency);
  };

  const getBarcodeMulti = async (barcode, safe) => {

    let item = null;
    let unitF = null;
    let ibance = null;


    if (barcode != null || safe != -1 || safe != null) {
      await axios
        .get(
          uri +
          `RptSafes/CurrCodesUnitsSub?currCode=${barcode}
        &&emp=${sto.empi}
        &&safe=${safe}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
          unitF = res.data?.unitsSub[0];
          ibance = res.data?.balance[0];
          setUnitsOptions(res.data?.unitsSub);
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null) {
        setSelectItems(false);
        await barcodeadd(item, unitF, ibance);
      } else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }

  };
  const barcodeadd = (dataCode, unitF, ibance) => {

    let percc = dataCode.perc;
    let prc = valueAutos.Type_Cost.id == 2 ? parseFloat(dataCode.sale) : parseFloat(dataCode.purch);
    let untBlnce=(parseFloat(ibance.Qtys) / parseFloat(dataCode.perc)).toFixed(2);

    setRptParamsSub({
      ...rptParamsSub,
      val1: 1,
      currency: dataCode.curr,
      barcode: dataCode.barcode,
      symbol: dataCode.symbol,
      qty_balance:ibance==null?0: parseFloat(ibance.Qtys),
      perc: dataCode.perc,
      price: rptParams.Type_Cost.id == 2 ? parseFloat(dataCode.sale) : parseFloat(dataCode.purch),
      salePrice: parseFloat(dataCode.sale),
      purchPrice: parseFloat(dataCode.purch),
      total: ((prc * 1)).toFixed(2),
      value: ((parseFloat(dataCode.perc) * 1)).toFixed(2),
      unit: dataCode.unit,
      unitName: dataCode.unitName,
      unit_balance:untBlnce,
    });
    let res = { unitsSub: {}, itemSub: {} };

    AutoOptions.items.map((x) => {
      if (x.id == dataCode.curr) res.itemSub = x;
    });

    setValueAutosSub({
      curr: res.itemSub,
      units: unitF,
    });
    setUnitsSubListTable([...unitsListTable, unitsOptions]);

  };

  const onSubmit = (e) => {
    e.preventDefault();

    let pric=0;
    let tot=(parseFloat(rptParamsSub.price)*parseFloat(rptParamsSub.val1)).toFixed(2);
    let val=(parseFloat(rptParamsSub.perc)*parseFloat(rptParamsSub.val1)).toFixed(2);
    setRptParamsSub({
      ...rptParamsSub,total:tot,value:val,
    });
    var oldData1 = paramsSubList.filter((obj) => obj.barcode == rptParamsSub.barcode && obj.unit == rptParamsSub.unit);
    console.log("the oldadata is", oldData1);


    if (oldData1.length != 0) {

      var oldData = paramsSubList.filter((obj) => obj.barcode == rptParamsSub.barcode && obj.unit == rptParamsSub.unit);

      let val1 = (parseFloat(oldData[0].val1) + parseFloat(1)).toFixed(2);
      let value = (parseFloat(oldData[0].value) + parseFloat(rptParamsSub.value)).toFixed(2);

      let tot = (parseFloat(oldData[0].total) + parseFloat(rptParamsSub.total)).toFixed(2);



      const dataUpdate = paramsSubList.filter((obj) => obj.barcode != rptParamsSub.barcode || obj.unit != rptParamsSub.unit);
      const musadata = [...dataUpdate, {
        ...oldData[0], val1: val1, value: value,
        total: tot,
      }];

      setParamsSubList([...musadata]);
      setSelectItems(true);
      setRptParamsSub(transsubvalue);
      //  handSums(musadata); 
    } else {

      setParamsSubList([...paramsSubList, rptParamsSub]);
      setSelectItems(true);
      setRptParamsSub(transsubvalue);
    }

  };

  const onChangeDataSub = (propName) => (event) => {
    setRptParamsSub({
      ...rptParamsSub, [propName]: event.target.value,
    });
  };

  const onChangeDataSubQ = (propName) => (event) => {
   
    setRptParamsSub({
      ...rptParamsSub, [propName]:event.target.value,
    });
  };
  const MathChangIdata = (prop, val) => {
    let i = {
      prc: Number(rptParamsSub.price),
      vl1: Number(rptParamsSub.val1),
      vlu: Number(rptParamsSub.value),
      tot: Number(rptParamsSub.total),
      sal: Number(rptParamsSub.salePrice),
      perc: Number(rptParamsSub.perc),
      prch: Number(rptParamsSub.purchPrice),
      sumtot: Number(0),
      sumval: Number(0),
      salv: Number(0),
      prchv: Number(0),
      res: Number(val),
    };
   
    if (prop === "price") {
        i.sumtot = i.res *i.vl1;
        i.sumval=i.vl1*i.perc;
        i.salv=valueAutos.Type_Cost.id==2?i.prc:i.sal;
        i.prchv=valueAutos.Type_Cost.id==1?i.prc:i.prch;
    }
    else if (prop === "val1") {
      i.sumtot = i.res *i.prc;
        i.sumval=i.res*i.perc;  
        i.salv=i.sal;
        i.prchv=i.prch;
    }
  
    setRptParamsSub({
      ...rptParamsSub,
      [prop]: i.res,
      total: i.sumtot,
      value: i.sumval,
      salePrice:i.salv,
      purchPrice:i.prchv,
    });
  };
  const onChangeDataSubP = (prop) => (event) => {
    MathChangIdata(prop,event.target.value);
    // let valall = 0;
    // if (event.target.value==null)event.target.value=0;
    // valall = (parseFloat(event.target.value) * parseFloat(rptParamsSub.val1));

    // let prcname = rptParams.Type_Cost == 2 ? "salePrice" : "purchPrice";
   
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({
      ...rptParams, [propName]: event.target.value, Type_Cost: valueAutos.Type_Cost.id
    });
  };

  const onChangeAutoData = (propName, newValue) => {
    setValueAutos({ ...valueAutos, [propName]: newValue });
    setRptParams({ ...rptParams, [propName]: newValue.id });
  };
  const onChangeAutoCost = (propName, newValue) => {
    setValueAutos({ ...valueAutos, [propName]: newValue });
    setRptParams({ ...rptParams, Type_Cost: newValue.id });

    if (paramsSubList.length > 0) {
      setParamsSubList(

        paramsSubList?.map((x) => {
          let prc_sal = 0.0;
          prc_sal = parseFloat(x.salePrice);
          let prc_pur = 0.0;
          prc_pur = parseFloat(x.purchPrice);

          let allsale = 0.0;
          let allpurch = 0.0;
          allsale = (parseFloat(x.salePrice) * parseFloat(x.val1)).toFixed(2);
          allpurch = (parseFloat(x.purchPrice) * parseFloat(x.val1)).toFixed(2);
          let prc_1 = 0.0;
          let tot_1 = 0.0;

          prc_1 = newValue.id == 2 ? parseFloat(prc_sal).toFixed(2) : parseFloat(prc_pur).toFixed(2);
          tot_1 = newValue.id == 2 ? parseFloat(allsale).toFixed(2) : parseFloat(allpurch).toFixed(2);
          return {
            ...x,
            price: prc_1,
            total: tot_1,
          }
        }));
    }
    if (rptParamsSub.barcode != null) {
      let tot = 0.0;
      let prc = 0.0;
      prc = newValue.id == 2 ? rptParamsSub.salePrice : rptParamsSub.purchPrice;
      tot = (parseFloat(prc) * parseFloat(rptParamsSub.val1)).toFixed(2);
      setRptParamsSub({ ...rptParamsSub, total: parseFloat(tot), price: parseFloat(prc) });
    }
  };

  const onChangeAutoDataSubunit = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    let allq = 0.0;
    allq = (parseFloat(newValue.perc) * parseFloat(rptParamsSub.val1)).toFixed(2);
    let prc = 0.0;
    prc = valueAutos.Type_Cost.id == 2 ? parseFloat(newValue.sale) : parseFloat(newValue.purch);
    let allt = 0.0;
    allt = (parseFloat(prc) * parseFloat(rptParamsSub.val1)).toFixed(2);
    let untBlnce=(parseFloat(rptParamsSub.qty_balance) / parseFloat(newValue.perc)).toFixed(2);
    setRptParamsSub({
      ...rptParamsSub, 
      unit: newValue.unit,
      perc: newValue.perc,
      price: valueAutos.Type_Cost.id == 2 ? newValue.sale : newValue.purch,
      value: allq, 
      cost_curr: newValue.purch, 
      total: allt,
      purchPrice: newValue.purch, 
      salePrice: newValue.sale,
      unitName:newValue.unitName,
      unit_balance:untBlnce,
    });
  };

  const onChangeAutoDataSubCurr = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    setRptParamsSub({ ...rptParamsSub, currency: newValue.id, symbol: newValue.name, barcode: newValue.barcode });
    getBarcodeMulti(newValue.barcode, rptParams.safe_from,newValue.id);
  };
  const getFormatSTransferData = (sTransferData, subData) => {
    setParamsSubList(
      subData.map((sub) => {
        let blncunit=(parseFloat(sub.qty_balance)/parseFloat(sub.perc)).toFixed(2);
        return {
          ...sub,
          currency: sub.currency,
          barcode: sub.barcode,
          symbol: sub.itemName,
          unit: sub.unit,
          unitName: sub.unitName,
          val1: sub.val1,
          total: sub.total,
          value: sub.value,
          price: sub.price,
          cost_curr: sub.cost_curr,
          perc: sub.perc,
          salePrice: sTransferData.Type_Cost == 2 ? sub.price : 0,
          purchPrice: sTransferData.Type_Cost == 2 ? 0 : sub.price,
          qty_balance: sub.qty_balance,
          unit_balance:blncunit,
        };
      }));
    setUnitsSubListTable(
      subData.map((sub) => {
        return {
          curId: sub.currency,
          unit: sub.unit,
          unitName: sub.unitName,
          sale: sTransferData.Type_Cost == 2 ? sub.price : 0,
          purch: sTransferData.Type_Cost == 2 ? 0 : sub.price,
          perc: sub.perc,
        };
      }));

    setRptParams({
      ...rptParams,
      safe_from: sTransferData.safe_from,
      safe_to: sTransferData.safe_to,
    });
    let res = { safe_from:{},safe_to:{}};
    AutoOptions.Safes.map((x) => {
      if (x.id == sTransferData.safe_from) res.safe_from = x;
    });
    AutoOptions.Safes.map((x) => {
      if (x.id == sTransferData.safe_to) res.safe_to = x;
    });
    
    setValueAutos({
      ...valueAutos,
      safe_from: res.safe_from,
      safe_to: res.safe_to,
    })
  };
  const getTransData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    importSTHandle(rptParams.transfer_no);
  };
  const importSTHandle = async (id) => {
    // setSubmitting(true)
    if (rptParams.safe_from == -1 || rptParams.safe_to == -1 || rptParams.safe_from == '' || rptParams.safe_to == '') {

      alert("تاكد من اختيار المخازن مع الفرع المحول الية");
    } else {
      await axios.get(uri + `StoreTransfer/import?transfer_id=${id}
          &&safe_from=${rptParams.safe_from}&&safe_to=${rptParams.safe_to}`).then(res => {
        if (valueAutos.safe_to.branch == rptParams.branch) {
          if (res.data?.importState == -1) {
            toast.error("رقم تحويل غير موجود")
          }
          else if (res.data?.importState == 0) {
            toast.error("تم استخدام رقم التحويل من قبل")
          }
          else {
            getFormatSTransferData({ ...res.data?.transferData, transfer_no: id,id:'', notes: "" }, res.data?.transferItems);
          }
        } else {
          toast.error("تأكد من اختيار الفرع الذي تم التحويل اليه")
        }

      }).catch(err => {
        console.log(err);
        toast.error("لم يتم جلب البيانات")
      });

    }
  };


  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => {
    his.goBack();

  };
  const cls = useRptAF();


  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);

  const clasBA = useStylesBA();
  const [transition, setTransition] = React.useState(undefined);

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });
  const ButtonWitting = (prop) => {
    reloadGets();

  };
  const handNewItem = () => {
    reloadGets();
    //const defaultTG = AutoOptions.tax_groups?.filter((t) => t.isDefault == true);

    setPrevent(insh.prevents);

    setParamsSubList([]);
    setRptParamsSub(transsubvalue);
    setUnitsOptions([]);
    setSelectItems(false);
    setValueAutosSub(defaultvalueAutosSub);
    setValueAutos(defaultvalueAutos);
    setEstelamAfter(false);
  };
  const timer = React.useRef();

  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop === "print") {printHandle();}
      else if (prop === "save"){ handNewItem();}
     else if (prop === "delete") {handNewItem();}
     else{
      handNewItem();
     }
      setPrevent(true);
    }, 2000);
  };
  const printHandle = () => {
      
    if (paramsSubList== 0) {
      alert("لاتوجد بيانات");
    } else {
      const info = {
        data: paramsSubList,
        tot: {
          count:paramsSubList.length,
          totQun:paramsSubList
          .reduce((a, v) => (a = a + parseFloat(v.val1)), 0)
          .toFixed(2),
          totCost:paramsSubList
          .reduce((a, v) => (a = a + parseFloat(v.value)), 0)
          .toFixed(2)
        },
        title: isEstelam ? " امر استلام مخزني مخزني  " : "أمر تحويل مخزني ",
        date:rptParams.date ,
        transNo:rptParams.no,
        nDate: moment(new Date()).format("YYYY-MM-DD"),
        branch: valueAutos.branch.name,
        safeFrom: valueAutos.safe_from.name,
        safeTo: valueAutos.safe_to.name,
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/ReviewTransfer" });
      window.location.reload(false);
    }
  };


  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", 1000);
    //handNewItem();
  };
  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });


  const addChangds = () => {
    rptParams.date =
      moment(rptParams.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");

      rptParams.branch=rptParams.Type==1?valueAutos.safe_from.branch:valueAutos.safe_to.branch;
      rptParams.branch_create=rptParams.Type==1?valueAutos.safe_from.branch:valueAutos.safe_to.branch;
    paramsSubList.price = valueAutos.Type_Cost.id == 0 ? 0 : paramsSubList.price;
    paramsSubList.total = valueAutos.Type_Cost.id == 0 ? 0 : paramsSubList.total;
  }
  const [openDw, setOpenDw] = useState(false);

  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenDw(true);
  };
  const handCloseDw = () => {
    setOpenDw(false);
  };
  const getSearchHeadData = (sTransferData,subData) => {
    setParamsSubList(
      subData.map((sub) => {
        let blncunit=(parseFloat(sub.qty_balance)/parseFloat(sub.perc)).toFixed(2);
        return {
          ...sub,
          currency: sub.currency,
          barcode: sub.barcode,
          symbol: sub.itemName,
          unit: sub.unit,
          unitName: sub.unitName,
          val1: sub.val1,
          total: sub.total,
          value: sub.value,
          price: sub.price,
          cost_curr: sub.cost_curr,
          perc: sub.perc,
          salePrice: sTransferData.Type_Cost == 2 ? sub.price : 0,
          purchPrice: sTransferData.Type_Cost == 2 ? 0 : sub.price,
          qty_balance: sub.qty_balance,
          unit_balance:blncunit,
        };
      }));
    setUnitsSubListTable(
      subData.map((sub) => {
        return {
          curId: sub.currency,
          unit: sub.unit,
          unitName: sub.unitName,
          sale: sTransferData.Type_Cost == 2 ? sub.price : 0,
          purch: sTransferData.Type_Cost == 2 ? 0 : sub.price,
          perc: sub.perc,
        };
      }));
    setRptParams({...rptParams,
      safe_from:sTransferData.safe_from,
      safe_to:sTransferData.safe_to,
      center:sTransferData.center,
      no:sTransferData.no,
      id:sTransferData.id,
      refno:sTransferData.refno,
      branch:sTransferData.branch,
      Type_Cost:sTransferData.Type_Cost,
      notes:sTransferData.notes,
      date:reFormatDate(sTransferData.date),
    });

    let res = { safe_from:{},safe_to:{},centers:{},typesCost:{}};
    AutoOptions.Safes.map((x) => {
      if (x.id == sTransferData.safe_from) res.safe_from = x;
    });
    AutoOptions.Safes.map((x) => {
      if (x.id == sTransferData.safe_to) res.safe_to = x;
    });
    typeTransfer.map((x) => {
      if (x.id == sTransferData.Type_Cost) res.typesCost = x;
    });
    AutoOptions.center.map((x) => {
      if (x.id == sTransferData.center) res.centers = x;
    });

    setValueAutos({...valueAutos,
      safe_from:res.safe_from,
      safe_to:res.safe_to,
      center:res.centers,
      Type_Cost:res.typesCost,
    })
  };

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });

  const addToBS = (props) => {
    if(estelamAfter && rptParams.Type==1){
      alert("لا يمكن تعديل هذا التحويل لارتباطه ب امر استلام...برجاء حذف الاستلام اولا");
    }else{
    if (paramsSubList.length > 0&&rptParams.safe_from !=="" && rptParams.safe_from !==null && rptParams.safe_to !="") {
      if (rptParams.safe_from !=="" && rptParams.safe_from !==null && rptParams.safe_to !=""){
      addChangds();
      ButtonLoading("save");
      let transSub = paramsSubList?.map(x => {
        console.log("thre group is ", x);
        return {
          transfer_id: x?.transfer_id,
          price: x?.price,
          total: x?.total,
          value: x?.value,
          unit: x?.unit,
          currency: x?.currency,
          user_emp: x?.user_emp,
          val1: x?.val1,
          sub_branch: x?.sub_branch,
          sub_center: x?.sub_center,
          cost_curr: x?.cost_curr,
          qty_balance: x?.qty_balance,
          transfered_val: x?.transfered_val,
        }
      })
      const currData = {
        transData: rptParams,
        transSubData: transSub,
        addEstelam: isEstelam ? 0 : valueAutos.alowEstlam.id,
      };
      console.log("the curre data is ", currData);

      prevent
        ? axios
          .post(uri + "StoreTransfer", currData)
          .then((res) => {
            if (res.data?.data!=null) {
              // alert("رقم الباردكود موجود مسبقا");
              setUnitsSubListTable([]);
              setParamsSubList([]);
              setUnitsOptions([]);
              ButtonSuccess(props, 1000);
            } else {
              setUnitsSubListTable([]);
              setParamsSubList([]);
              setUnitsOptions([]);
              ButtonSuccess(props, 1000);
            }

          })
          .catch((res) => {
            ButtonSuccess(props, 1000);
          })

        :
        axios
          .put(uri + "StoreTransfer", currData)
          .then((res) => {
            if (res.data?.data!=null) {
              setUnitsSubListTable([]);
              setParamsSubList([]);
              setUnitsOptions([]);
              ButtonSuccess(props, 1000);
            } else {
              setUnitsSubListTable([]);
              setParamsSubList([]);
              setUnitsOptions([]);
              ButtonSuccess(props, 1000);
            }
          })
          .catch((res) => {
            ButtonSuccess(props, 1000);            
          });
        }else{
          alert("تأكد من اكمال البيانات  ");

        }
    } else {
      alert("تأكد من اكمال البيانات  ");
    }
  }
  };

  const deleteToI = async (e) => {

    if(estelamAfter){
      alert("لا يمكن حذف هذا التحويل لارتباطه ب امر استلام...برجاء حذف الاستلام اولا");
    }else{

    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    ButtonLoading("delete");

    await axios.delete(uri + `StoreTransfer?id=${rptParams.id}`).then(res => {
      toast.success("تم الحذف بنجاح");
      ButtonSuccess("delete", 1000);

    }).catch(err => {
      console.log("eror", err)
      ButtonSuccess("delete", 1000);
      toast.error("not delete ")
    })
  };
  };

  const deleteToI2 = async (e) => {
    if(estelamAfter && rptParams.Type==1){
      alert("لا يمكن حذف هذا التحويل لارتباطه ب امر استلام...برجاء حذف الاستلام اولا");
    }else{
    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    ButtonLoading("delete");
    axios
      .delete(uri + `StoreTransfer?id=${rptParams.id}`)
      .then((res) => {
        ButtonSuccess("delete", 1000);
      })
      .catch((err) => {
        alert("لم يتم الحذف");
        ButtonSuccess("delete", 1000);
       // handNewItem();
      });
    }
  };
  const reloadGets = async () => {
    const tb = {
      wait: true,
      other: true,
      // Items: true,
      parms: {
        iempid: sto.empi,
        tname:isEstelam?"Estelam":"Transfer",
        branch:sto.branchi

        // takei: 1,
      },
    };

    await axios
      .post(uri + "GetD", tb)
      .then((res) => {
        setRptParams({
          ...defaultTransvalue,
          no: res.data.other.lastid,
          branch: AutoOptions.Branches[0].id,
          safe_from:isEstelam?'':AutoOptions.Safes[0].id,
          safe_to:isEstelam?AutoOptions.Safes[0].id:'',
        });
      })
      .catch((error) => {
      });
  };

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {isEstelam?"أمر استلام مخزني":"أمر تحويل مخزني"}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.blue[50],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          {/* <SafesTransferFilter {...{ valueAutos, setValueAutos }} /> */}

          <SafesTranferHead
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              valueAutos,
              typeTransfer,
              onChangeAutoCost,
              isEstelam,
              getTransData,
              MathChangIdata
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >

          </Grid>
        </Container>
        {/* <br /> */}
        <Container maxWidth={false} style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "15px" }}
        >
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}>
            <SafesTransfersForm
              {...{
                AutoOptions,
                rptParamsSub,
                onChangeDataSub,
                onChangeAutoDataSubunit,
                valueAutosSub,
                selectItems,
                unitsOptions,
                onChangeBarcodeSearch,
                onChangeAutoDataSubCurr,
                onSubmit,
                onChangeDataSubQ,
                onChangeDataSubP,
              }}
            />

          </Grid>
        </Container>
        {/* <br /> */}
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.blue[50]} />
              {!fetching && (
                <SafesTransferTables
                  {...{
                    paramsSubList,
                    rptParams,
                    valueAutosSub,
                    setValueAutosSub,
                    unitsOptions,
                    setParamsSubList,
                    estelamAfter,
                  }}
                />
              )}
            </Grid>
            {paramsSubList.length > 0 && (
              <Grid
                container
                lg={12}
                md={12}
                className={cls.rootBdydown}
                style={{
                  paddingBottom: 5, marginTop: "1rem",
                  backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular"
                }}
              >
                <Grid container xs={12} /*className={cls.rootchip}*/ style={{
                  paddingBottom: 5,
                  backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular",
                  border: "solid 1px",
                  padding: "0.3rem 0.1rem 0.3rem 0.1rem",
                  borderRadius: "0px 0px 5px 0px",
                }}>

                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {" عدد الأصناف"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList.length}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {"إجمالي الكميات"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList
                      .reduce((a, v) => (a = a + parseFloat(v.val1)), 0)
                      .toFixed(2)}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {"إجمالي المبلغ"}
                  </Grid>
                  <Grid item xs={2} className={[cls.chipVMU]}>
                    {paramsSubList
                      .reduce((a, v) => (a = a + parseFloat(v.total)), 0)
                      .toFixed(2)}
                  </Grid>
                </Grid>

              </Grid>)}
          </Grid>

        </Container>
      </Typography>

      <SalesTransferBar  {...{
        rptParams,
        setBarcodeDialogOpen,
        buttonClassnameNew,
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        newToI,
        setting,
        stElemnt,
        loading,
        clasBA,
        backHome,
        back,
        uiElement,
        handOpenDw,
        TransitionUp,
        addToBS,
        setOpenDw,
        estelamAfter,
        deleteToI,
        printHandle,
        prevent,
      }} />
      <TransfersSearch {...{
        isEstelam,
        getFormatSTransferData,
        handOpenDw,
        TransitionUp,
        setPrevent,
        openDw,
        transition,
        handCloseDw,
        getSearchHeadData,
        setEstelamAfter,
      }}
      />
    </div>
  );
}
