import React from "react";
import { uri } from "../../../../../help/Api";
import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh, sto } from "../../../../../Context/InshData";
import BackdropScI from "../../../../../services/BackdropScI";
import EExchangeVoucher from "./EExchangeVoucher";
import dataF from "../../../../../Server/dataF.json"


function EExchangeVoucherItem(props){

  
        const tttb ={
    /*other:true,*/ // out
    wait:true,
    other:true,
    Users:true,
    Stocks:true, // Error // out
    Banks:true,
    Customers:true,
    salesmen:true,
    costcenters:true,
    Employees:true,
    CheckStatus:true,
    /*params*/ // out
     PermissionsElemnts:true,
    /*params*/ // out
    parms:{
      ibranchid:sto.branchi, iempid:sto.empi,
      iuserid:sto.idi, 
      scurrenciestype:'I',
      branch:sto.branchi,
      tname:"sandd",
      iformid: dataF[67].id,
    },
    autos:{
      Users:true,
      Safes:true,
      Stocks:true,
      Banks:true,
      Employees:true,
      salesmens:true,
      costcenters: true,
      CheckStatus: true,
    }
  }

    const url = uri+'Res';

    const {object:AutoOptions, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, tttb);
    
        useEffect(()=>{
        console.log('customer',AutoOptions)
    })
      if(fetchinData || !AutoOptions?.Customers )
        return <BackdropScI />
    return (
        <EExchangeVoucher
        {...{
            AutoOptions: AutoOptions?.Customers? AutoOptions: [],
            fetchURL,
            prmis: AutoOptions?.permisElm?? insh.permiElm,
            ...props,
        }}/>
    )
}

   

export default function EExchangeVoucherCon(){

    return (
      
        <EExchangeVoucherItem
        {...{
          isDialog: false,
          isDialogData: false,

        }}/>
    )
}

export function EExchangeVoucherLink(props){
  const {data: item, onClose,} = props;

    return (
        <EExchangeVoucherItem
        {...{
            isDialog: true,
            item,
            onClose,
            isDialogData: false,

        }}/>
    )
}
