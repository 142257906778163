import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { reFormatDate } from "../../../../utils/utils";
import { docTypes } from "./accountingRPT-services";
import axios from "axios";
import { uri } from "../../../../help/Api";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function AccountsRPTTable(props) {
    const { data,setInvData,handleOpenInvData ,handleOpenSandQData ,handleOpenSandSData,handleOpenSandSDData
        ,handleOpenSandQDData,handleOpenDailyRest,handleOpenFirstRest
    } = props;

    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }
    const onRowClicks = async(row) => {
        setInvData({});
        await axios
        .get(
          uri+
            `AccountReports/GetInvsData?branch=${row?.branch}
    &&resId=${row?.id}&&doc_type=${row?.type}`
        )
        .then((res) => {
          if (row?.type===1 || row?.type===2|| row?.type===3||row?.type===4 || row?.type===9) {
            setInvData(res.data.invData[0]);
            handleOpenInvData();
          } else if (row?.type===5) {
            setInvData(res.data.invData[0]);
            handleOpenSandQData();
          }
          else if (row?.type===6) {
            setInvData(res.data.invData[0]);
            handleOpenSandSData();
          }
          
          else if (row?.type===8) {
            setInvData(res.data.invData[0]);
            handleOpenSandSDData();
          }
          else if (row?.type===7) {
            setInvData(res.data.invData[0]);
            handleOpenSandQDData();
          }
          else if (row?.type===10) {
            setInvData(res.data.invData[0]);
            handleOpenDailyRest();
          }
          else if (row?.type===11) {
            setInvData(res.data.invData[0]);
            handleOpenFirstRest();
          }
        
        })
        .catch((err) => {
            console.log(err);
        });
      };


    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: colors.grey[700],
                            color: "#fff",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("balance")}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("dept")}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("credit")}{" "}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {"رقم المستند"}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("date")}{" "}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("doc-type")}{" "}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("ref-no")}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {t("note")}{" "}
                        </TableCell>
                        <TableCell style={{ color: "#fff", fontWeight: "bold" }} align="center">
                            {"Action"}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.val1}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 15,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} align="center">{row.balance?.toFixed(2)}</TableCell>
                            <TableCell style={stylRo} align="center">{row.dept.toFixed(2)}</TableCell>
                            <TableCell style={stylRo} align="center">{row.credit.toFixed(2)}</TableCell>
                            <TableCell style={stylRo} align="center">{row?.doc_no}</TableCell>
                            <TableCell style={stylRo} align="center">{reFormatDate(row?.date)}</TableCell>
                            <TableCell style={stylRo} align="center">
                                {row?.type > 0 && row?.type < 13 ? (
                                   docTypes[row?.type]?.name
                                ) : null}
                            </TableCell>
                            <TableCell style={stylRo} align="center">{row.ref_no}</TableCell>
                            <TableCell style={stylRo} align="center">{row.notes}</TableCell>

                            <TableCell style={stylRo} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                       onRowClicks(row);
                                    }}
                                   style={{backgroundColor:"#fff" ,color:"#000" ,fontWeight:"bold"}}
                                >
                                    {"تفاصيل"}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
