import React, { useEffect } from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { useStylesBA, useStylesCAB } from "../../../../../St/comps/UseStyle";
import toast, { Toaster } from "react-hot-toast";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SaveIcon from "@material-ui/icons/Save";
import ScannerIcon from "@material-ui/icons/Scanner";
import PrintIcon from "@material-ui/icons/Print";
import { Fragment } from "react";
import {
  EExchangeValidateSchema,
  EReceiptValidateSchema,
  PrepareData,
  ReFormatingData,
} from "../../../../../services/EReceiptVoucher-service";
import { useState } from "react";
import { cellS, headerS, stElemnt } from "../../../../../St/comps/cStyle";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ReceiptSearchModal from "../components/Receipt-search-modal";
import { IRReFormatingData } from "../../../../../services/IReceipt-service";
import { useTranslation } from "react-i18next";
import { paymentType } from "../components/ReceiptVoucher-typeFilter";
import { Delete } from "@material-ui/icons";
import PagenationButtons from "../../../../../components/controls/PagenationButons";
import { sto } from "../../../../../Context/InshData";
import SearchReaceiptCon from "../components/SearchRecipts/SearchReaceiptCon";

var columnsInv = [
  { title: "الرقم", field: "id", headerStyle: headerS, cellStyle: cellS },
  { title: "التاريخ", field: "date", headerStyle: headerS, cellStyle: cellS },
  { title: "المبلغ", field: "val", headerStyle: headerS, cellStyle: cellS },
  {
    title: "مندوب المبيعات",
    field: "empName",
    headerStyle: headerS,
    cellStyle: cellS,
  },
  {
    title: "المرجع",
    field: "check_no",
    headerStyle: headerS,
    cellStyle: cellS,
  },
  {
    title: "الحساب",
    field: "custName",
    headerStyle: headerS,
    cellStyle: cellS,
  },
  { title: "البيان", field: "notes", headerStyle: headerS, cellStyle: cellS },
];

export default function EExchangeVoucherAppBar(props) {
  const {
    submit,
    prmis,
    isDialog,
    onClose,
    update,
    remove,
    defaultV,
    submitting,
    onShow,
    object,
    URL,
    formType,
    getRow,
    AutoOptions,
  } = props;
  let his = useHistory();
  const { t } = useTranslation(["forms"]);

  const [openSearchMdl, setOpenSearchMdl] = useState(false);
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();
  const styBtn = {
    dis: { color: "#3b773d", background: "#4fa152" },
    undis: { color: "#fff" },
  };

  const newEReceipt = () => {
    onShow(defaultV, "new");
    toast("تم تهيئة النموذج لإضافة بيانات جديدة");
  };
  const addChangds = () => {
    object.date =
      moment(object.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");
  }
  const onSubmitHandle = (isPrint, isLarge = false) => {
    addChangds();
    let pData = PrepareData(object);
    let nData = {
      ...pData,
      emp_id: object.user.id,
    };
    const data = {
      sandD: nData,
      stor: { invsType: 1 },
    };

    EExchangeValidateSchema.validate(nData, { abortEarly: false })
      .then(async () => {
        if (formType == "edit") {
          if (isPrint) handlePrint(nData, nData, isLarge);
          else await update(data).then((res) => { 
            if(props.isDialogData===true){
              props.onClose();
            }
          });
        } else {
          await submit(data).then((res) => {
            if (isPrint) handlePrint(res.data, nData, isLarge);
          });
        }
      })
      .catch((err) => {
        toast.error(err.errors[0]);
      });
  };

  const handlePrint = (rptdata, oldObject, isLarge) => {
    const info = {
      temp: 2,
      columns: columnsInv,
      data: rptdata,
      custN: "دفعة لحساب",
      from: "الصرف",
      title: "سند صرف - خارجي",
      subTitle: "External Exchange Voucher",
      label: "صرفنا الى السيد",
      auto: {
        empName: oldObject.emp.name,
        center: oldObject.center.name,
        custName: oldObject.customer.name,
        stockName:
          rptdata?.type == 1 ? oldObject.stock?.name : oldObject.bank?.name,
        type: t(paymentType[rptdata?.type - 1].name),
      },
      //   fDate:dateFrom,tDate:dateTo,
      Date: moment(rptdata.date).format("YYYY-MM-DD"),
      ElmntShow: true,
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    if (isLarge) his.push({ pathname: "/voucher2" });
    else his.push({ pathname: "/SC" });
    window.location.reload(false);
  };
  const onRowDialog = () => {
    if (props.isDialogData == true) {

      let data = props.invData;
      const data2 = {
        ...data,
        acc_name: data.acc_name,
        custName: data.acc_name,
        emp_id: data.sales_emp,
      }
      console.log("the rowssss is", data2)
      let FormatedData = IRReFormatingData(data2, AutoOptions);
      onShow(FormatedData, "edit");
      setOpenSearchMdl(false);
    }

  };

  useEffect(() => {
    // console.log("the rowssss is", props.invData)

    onRowDialog();
  }, []);
  const onRowClick = (row) => {
    let FormatedData = IRReFormatingData(row, AutoOptions);
    onShow(FormatedData, "edit");
    console.log("type is", FormatedData);
    setOpenSearchMdl(false);
  };

  const onGetRow = (type) => async (e) => {
    await getRow({
      branch: sto.branchi,
      id: object.id,
      type: type
    }).then(res => {
      if (res != null) {
        onRowClick(res?.data);
      }
      console.log("the resposent is ", res)
    });

  }

  const onDeleteHandle = async () => {
    await remove(object.id)
      .then((res) => {
        if(props.isDialogData===true){
          props.onClose();
        }
       })
      .catch((err) => console.log(err));
  };

  const backHome = () => {
    if (isDialog) onClose(false);
    else {
      his.push({ pathname: "/" });
      window.location.reload(false);
    }
  };
  const [transition, setTransition] = React.useState(undefined);
  const [isInternal, setIsInternal] = useState(false);

  let type = 1;
  let tname = "1";
  const handCloseDw = () => {

    setOpenSearchMdl(false);
  };

  return (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clasBA.appBarBASal}
        style={{ backgroundColor: "#808080" }}
      >
        <Toolbar className={clasBA.ToolbarBA} variant="dense">
          {props.isDialogData ? (
            <>
              <Hidden smUp={["sm"]}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="backH"
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>

              </Hidden>

              <Hidden only={["xs"]}>
                <Button
                  style={styBtn.undis}
                  onClick={props.onClose}
                  startIcon={<CloseIcon />}
                >
                  {t("exit")}
                </Button>

              </Hidden>
            </>
          )
            :
            (
              <>
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="backH"
                    onClick={backHome}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="searsh"
                    disabled={prmis?.Se}
                    onClick={() => setOpenSearchMdl(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="handNew"
                    onClick={newEReceipt}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={styBtn.undis}
                    onClick={backHome}
                    startIcon={<CloseIcon />}
                  >
                    {t("exit")}
                  </Button>
                  <Button
                    style={styBtn.undis}
                    onClick={() => setOpenSearchMdl(true)}
                    disabled={prmis?.Se}
                    startIcon={<SearchIcon />}
                  >
                    {t("search")}
                  </Button>
                  <Button
                    style={styBtn.undis}
                    onClick={newEReceipt}
                    startIcon={<AddBoxIcon />}
                  >
                    {t("new")}
                  </Button>
                </Hidden>
              </>
            )}


          <div className={clasBA.growBA} />

          <Hidden smUp={["sm"]}>
            {formType == "edit" && (
              <div className={clasCAB.wrapperCAB}>
                <Fab
                  size="medium"
                  color="primary"
                  // disabled
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={submitting || prmis?.De}
                  className={clasCAB.buttonSuccessCAB}
                  onClick={onDeleteHandle}
                >
                  <Delete />
                </Fab>

                {submitting && (
                  <CircularProgress
                    size={56}
                    className={clasCAB.fabProgressCAB}
                  />
                )}
              </div>
            )}
            <div className={clasCAB.wrapperCAB}>
              <Fab
                size="medium"
                color="primary"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting ||
                  (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                }
                onClick={() => {
                  onSubmitHandle(true);
                }}
              >
                <ScannerIcon />
              </Fab>
              {submitting && (
                <CircularProgress
                  size={56}
                  className={clasCAB.fabProgressCAB}
                />
              )}
            </div>
            <div className={clasCAB.wrapperCAB}>
              <Fab
                size="medium"
                color="primary"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting ||
                  (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                }
                onClick={() => {
                  onSubmitHandle(true, true);
                }}
              >
                <PrintIcon />
              </Fab>
              {submitting && (
                <CircularProgress
                  size={56}
                  className={clasCAB.fabProgressCAB}
                />
              )}
            </div>

            <div className={clasCAB.wrapperCAB}>
              <Fab
                size="medium"
                color="primary"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                }
                onClick={() => onSubmitHandle(false)}
              >
                {" "}
                <SaveIcon />
              </Fab>

              {submitting && (
                <CircularProgress
                  size={56}
                  className={clasCAB.fabProgressCAB}
                />
              )}
            </div>
          </Hidden>

          <Hidden only={["xs"]}>
            {formType == "edit" && (
              <>
                <div className={clasCAB.wrapperCAB}>
                  <PagenationButtons
                    variant="contained"
                    color="#fff"
                    size="large"
                    className={clasCAB.buttonSuccessCAB}
                    startIcon={<Delete />}
                    style={{
                      ...stElemnt.all.iconBtn,
                      backgroundColor: "#808080",
                    }}
                    disabled={submitting}
                    onClick={onGetRow}

                  />

                  {submitting && (
                    <CircularProgress
                      size={24}
                      className={clasCAB.buttonProgressCAB}
                    />
                  )}
                </div>
                <div className={clasCAB.wrapperCAB}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={clasCAB.buttonSuccessCAB}
                    startIcon={<Delete />}
                    style={{
                      ...stElemnt.all.iconBtn,
                      backgroundColor: "#808080",
                    }}
                    disabled={submitting || prmis.De}
                    onClick={onDeleteHandle}
                  >
                    {t("delete")}
                  </Button>

                  {submitting && (
                    <CircularProgress
                      size={24}
                      className={clasCAB.buttonProgressCAB}
                    />
                  )}
                </div>
              </>
            )}

            <Fragment>
              <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={clasCAB.buttonSuccessCAB}
                  startIcon={<ScannerIcon />}
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={
                    submitting ||
                    (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                  }
                  onClick={() => onSubmitHandle(true)}
                // onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
                >
                  {" "}
                  {t("cashier-view")}
                </Button>

                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
              <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={clasCAB.buttonSuccessCAB}
                  startIcon={<PrintIcon />}
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={
                    submitting ||
                    (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                  }
                  onClick={() => onSubmitHandle(true, true)}
                // onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
                >
                  {" "}
                  {formType == "edit" ? t("print") : t("save-print")}
                </Button>

                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
              <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  className={clasCAB.buttonSuccessCAB}
                  disabled={
                    submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                  }
                  onClick={() => onSubmitHandle(false)}
                  startIcon={<SaveIcon />}
                >
                  {formType == "edit" ? t("save-update") : t("save")}
                </Button>
                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
            </Fragment>
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* <ReceiptSearchModal
        URL={URL}
        open={openSearchMdl}
        onClose={() => {
          setOpenSearchMdl(false);
        }}
        onRowClick={onRowClick}
      /> */}
      <SearchReaceiptCon
        {...{
          openSearchMdl, handCloseDw,
          onRowClick, URL, type, tname, AutoOptions, transition, isInternal,
        }}
      />
    </React.Fragment>
  );
}
