import React, { useEffect } from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { useStylesBA, useStylesCAB } from "../../../../St/comps/UseStyle";
import toast, { Toaster } from "react-hot-toast";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import { useState } from "react";
import { stElemnt } from "../../../../St/comps/cStyle";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { restrictionValidateSchema } from "../Restrictions/restriction-validate-schema";
import {
  PrepareData,
  ReFormatingData,
  ReFormatingData2,
} from "../../../../services/restrictionService";
import RestrictionSearchModal from "../Restrictions/restriction-search-modal";
import moment from "moment";
import { sto } from "../../../../Context/InshData";
import PagenationButtons from "../../../../components/controls/PagenationButons";
import SearchReaceiptContainer from "../Restrictions/RetrictionsSearch/RetrictionsSearchCon";
import axios from "axios";
import { uri } from "../../../../help/Api";

export default function ORestrAppBar(props) {
  const {
    submit,
    isReceipt,
    isDialog,
    onClose,
    prmis,
    update,
    defaultV,
    submitting,
    onShow,
    object,
    URL,
    formType,
    getRow,
    AutoOptions,
  } = props;
  const { t } = useTranslation(["forms"]);
  let his = useHistory();
  const [openSearchMdl, setOpenSearchMdl] = useState(false);
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();
  const styBtn = {
    dis: { color: "#3b773d", background: "#4fa152" },
    undis: { color: "#fff" },
  };

  const newObject = () => {
    onShow(defaultV, "new");
  };
  const addChangds = () => {
    object.date =
      moment(object.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");
  }
  const submiteRestriction = (type) => async (e) => {
    addChangds();
    e.preventDefault();
    if (object.rows?.length == 0) {
      toast.error("لا يوجد حسابات في القيد");
    } else {
      let model = PrepareData(object);
      console.log("the model is", model);
      const save = await restrictionValidateSchema
        .validate(model?.restriction, { abortEarly: false })
        .then(async function (valid) {
          if (formType == "edit") {
            await update(model).then((res) => {
              if (type == "print") { print(); }
              else {
                if (props.isDialogData == true) {
                  props.onClose();
                }
              }
            });
          } else {
            await submit(model).then((res) => {
              if (type == "print") print();
            });
          }
        })
        .catch(function (err) {
          toast.error(err.errors[0]);
        });
    }
  };
  const print = () => {
    console.log("the impotant is", object.rows);
    const info = {
      data: object.rows,
      tot: null,
      type: 1,
      accBook: "قيود افتتاحية",
      doc_no2: object.doc_no2,
      notes: object.notes,
      total_dept: object.total_dept,
      total_credit: object.total_credit,
      nDate: moment(object.date).format("YYYY-MM-DD"),
      tDate: "00:00:00",
    };

    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/RP" });
    window.location.reload(false);
  };
  const onRowClick = (row) => {
    let FormatedData = ReFormatingData2(row, AutoOptions);
    console.log("data is", FormatedData);
    onShow(FormatedData, "edit");
    setOpenSearchMdl(false);
  };
  const onRowDataClick = async (row, subData) => {
    console.log("data row", row);
    let FormatedData = ReFormatingData(row, subData, AutoOptions);
    console.log("data is", FormatedData);
    onShow(FormatedData, "edit");
    setOpenSearchMdl(false);
  };


  const FromDialog = async () => {
    if (props.isDialogData === true) {
      let data = props.invData;

      await axios
        .get(
          uri +
          `AccountReports/RestrictionSubSearch?branch=${data?.branch}
&&resId=${data?.id}`
        )
        .then((res) => {
          if (res.data) {
            onRowDialog({ ...data }, res?.data?.restSubData);
          } else {
            onRowDialog({ ...data }, res?.data?.restSubData);
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onRowDialog = async (row, subData) => {
    if (props.isDialogData === true) {

      console.log("data row", row);
      let FormatedData = ReFormatingData(row, subData, AutoOptions);
      console.log("data is", FormatedData);
      onShow(FormatedData, "edit");
      setOpenSearchMdl(false);
    }
  };



  useEffect(() => {
    // console.log("the rowfffssss is", props.invData)

    FromDialog();
  }, []);


  const onGetRow = (gtype) => async (e) => {
    await getRow({
      branch: sto.branchi,
      id: object.id,
      gettingType: "11," + gtype
    }).then(res => {
      if (res != null) {
        onRowClick(res?.data);
      }
      console.log("the resposent is ", res)
    });
  }
  const backHome = () => {
    if (isDialog) onClose();
    else {
      his.push({ pathname: "/" });
      window.location.reload(false);
    }
  };
  const [transition, setTransition] = React.useState(undefined);
  const [isInternal, setIsInternal] = useState(true);
  const handCloseDw = () => {

    setOpenSearchMdl(false);
  };

  return (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clasBA.appBarBASal}
        style={{ backgroundColor: "#808080" }}
      >
        <Toolbar className={clasBA.ToolbarBA} variant="dense">
          {props.isDialogData ?
            (
              <>
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="backH"
                    onClick={props.onClose}
                  >
                    <CloseIcon />
                  </IconButton>

                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={styBtn.undis}
                    onClick={props.onClose}
                    startIcon={<CloseIcon />}
                  >
                    {t("exit")}
                  </Button>

                </Hidden>
              </>
            ) :
            (
              <>
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="backH"
                    onClick={backHome}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="searsh"
                    onClick={() => setOpenSearchMdl(true)}
                    disabled={prmis?.Se}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="handNew"
                    onClick={newObject}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={styBtn.undis}
                    onClick={backHome}
                    startIcon={<CloseIcon />}
                  >
                    {t("exit")}
                  </Button>
                  <Button
                    style={styBtn.undis}
                    disabled={prmis?.Se}
                    onClick={() => setOpenSearchMdl(true)}
                    startIcon={<SearchIcon />}
                  >
                    {t("search")}
                  </Button>
                  <Button
                    style={styBtn.undis}
                    onClick={newObject}
                    startIcon={<AddBoxIcon />}
                  >
                    {t("new")}
                  </Button>
                </Hidden>
              </>
            )}
          <div className={clasBA.growBA} />

          <Hidden smUp={["sm"]}>
            {/* {object.formType=='edit' &&
        <div className={clasCAB.wrapperCAB}>
      <Fab size="medium" color="primary" disabled
        style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}} 
        className={clasCAB.buttonSuccessCAB}
        // onClick={deleteTos} 
        >
         <DeleteIcon />
        </Fab>
       
    {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>
    } */}

            <div className={clasCAB.wrapperCAB}>
              <Fab
                size="medium"
                color="primary"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                }
                onClick={submiteRestriction("save")}
              >
                {" "}
                <SaveIcon />
              </Fab>

              {submitting && (
                <CircularProgress
                  size={56}
                  className={clasCAB.fabProgressCAB}
                />
              )}
            </div>
          </Hidden>

          <Hidden only={["xs"]}>
            {formType == "edit" && (

              <div className={clasCAB.wrapperCAB}>
                <PagenationButtons
                  variant="contained"
                  color="#fff"
                  size="large"
                  className={clasCAB.buttonSuccessCAB}
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={submitting}
                  onClick={onGetRow}

                />

                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>)}
            {/* {
            formType=='edit' &&
            <div className={clasCAB.wrapperCAB}>
            <Button variant="contained" color="primary" size="large" 
                className={clasCAB.buttonSuccessCAB} 
                startIcon={<DeleteIcon />}
                style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}}
                disabled={submitting}
                // onClick={deleteTos}
                >حـذف</Button>

            {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
            </div>
        } */}
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={clasCAB.buttonSuccessCAB}
                startIcon={<PrintIcon />}
                style={{
                  ...stElemnt.all.iconBtn,
                  backgroundColor: "#808080",
                }}
                disabled={
                  submitting ||
                  (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                }
                onClick={submiteRestriction("print")}
              // onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
              >
                {" "}
                {formType == "edit" ? t("print") : t("save-print")}
              </Button>
              {submitting && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                }
                onClick={submiteRestriction("save")}
                startIcon={<SaveIcon />}
              >
                {formType == "edit" ? t("save-update") : t("save")}
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* <RestrictionSearchModal
        {...{
          open: openSearchMdl,
          type: 11,
          onClose: () => {
            setOpenSearchMdl(false);
          },
          onRowClick: onRowClick,
        }}
      /> */}
      <SearchReaceiptContainer
        {...{
          openSearchMdl, handCloseDw,
          onRowDataClick, type: 11, AutoOptions, transition,
        }}
      />
      {/* <MultiSearchModal
        URL={URL}
        isReceipt={isReceipt}
        open={openSearchMdl}
        onClose={() => {
          setOpenSearchMdl(false);
        }}
        onRowClick={onRowClick}
      /> */}
    </React.Fragment>
  );
}
