import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import LoadingPage from "../../../../services/LoadingPage";
import dataF from "../../../../Server/dataF.json";
import { useHistory, useLocation } from "react-router-dom";
import InvoicesMain from "../invoiceMain";
import { getLS } from "../../../../utils/useLS";
const defaultAutos = {
  Safes: [],
};
export default function InvoicesContianers() {
    let locR = getLS("DataRem", {});
    const location = useLocation();
    let branchi= location.state.idata.branch;
    let Prctype= location.state.setting.PrTy_InPr;
    let usercrt= location.state.idata.create_user;
   let invtype= location.state.stor.invsType;
   let locForm = location.state.setting.iformid;
  const tb = {
    other: true,
    wait: true,
    CustomersInv:true,
    SafesInv: true,
    StocksInv: true,
    salesmen: true,
    BankInv:true,
    costcenter:true,
    defaultSalesUser:true,
    InvItems:true,
    PermissionsElemnts: true,
    PermissionsCuCo: true,
    Branches:true,
    MaxShifts:true,
    PermissionsCuCoPurch: invtype == 3 || invtype == 4 || invtype == 6,
    Items: true,
    groupSub: true,
    PermissionsInvs:true,
    UsersA: true,
    typesInv:true,
    stor: { ...sto,branch:sto.branchi},
    parms: { 
      tname:  invtype == 7?"make": "invs",
        branch:sto.branchi,
        iempid: sto.empi,
        scurrenciestype: "C",
        takei: 40,
        iformid:invtype === 1?40002:invtype===4?40001: locForm,
       iformidCuCo: dataF[3].id /* for curr and yashml */,
        sProcTypeInvProc: Prctype, 
     },

  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Customers   
  )
    return <LoadingPage />;

  return (
    <InvoicesMain
      {...{
        AutoOptions,
        isPurches: invtype == 3 || invtype == 4 || invtype == 6?true:false,
        isStock:invtype==5 || invtype==7 ?true:false,
        isMake:invtype==7 ?false:false,
        isDialog:false, 
      }}
    />
  );
}
