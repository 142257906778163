import React from "react";
import { uri } from "../../../../help/Api";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh, sto } from "../../../../Context/InshData";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { accountsURL } from "../../../../api/EReceipt";
import DRestrictions from "./daily-restrictions";
import LoadingPage from "../../../../services/LoadingPage";
import { getReFormatingAccountOmla } from "../../../../services/global-services";
import { Dialog } from "@material-ui/core";

export default function DRestrictionsDialog(props) {
    const { open, onClose, onSave, invData } = props;

    const tttb = {
        wait: true,
        other: true,
        costcenters: true,
        branches: true,
        omlat: true,
        UsersA: true,
        AccountBooks: true,
        PermissionsElemnts: true,
        /*params*/ // out
        parms: {
            ibranchid: sto.branchi,
            iempid: sto.empi,
            iuserid: sto.idi,
            // branch: sto.branchi,
            tname: "drest",

            withOmlat: true,
            iformid: "60002",
        },
        autos: {
            branches: true,
            costcenters: true,
            AccountBooks: true,
            omlat: true,
        },
    };

    useEffect(() => {
        console.log("hello ", AutoOptions);
    });

    const url = uri + "Res";

    const {
        object: AutoOptions,
        fetching: fetchinData,
        fetch: fetchURL,
    } = useFetchObjectByPost(url, tttb);
    const {
        data,
        fetching,
        fetchURL: fetchURL1,
    } = useFetchURL({
        ...{
            url: accountsURL,
            mapFunction: getReFormatingAccountOmla,
        },
    });
    if (fetchinData || !AutoOptions?.costcenters || !data || fetching)
        return <LoadingPage />;

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <DRestrictions
                {...{
                    AutoOptions: AutoOptions?.costcenters
                        ? { ...AutoOptions, Accounts: data }
                        : [],
                    fetchURL,
                    prmis: AutoOptions?.permisElm ?? insh.permiElm,
                    ...props,
                    invData,
                    isDialog: false,
                    isDialogData: true,
                }}
            />

        </Dialog>

    );
}
