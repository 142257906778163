import React from "react";
import { uri } from "../../../../../help/Api";
import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { insh, sto } from "../../../../../Context/InshData";
import BackdropScI from "../../../../../services/BackdropScI";
import IReceiptVoucher from "./IReceiptVoucher";

import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { accountsURL, requestData } from '../../../../../api/IReceipt';
import { useEffect } from "react";
import { Dialog } from "@material-ui/core";

function getReFormatingAccount(data){

    const newData = data.map((row, i)=> {
            return {id: row.id,
                 name:row.symbol,
                 code: row.barcode,
                } 
        })
    return newData
}

export default  function ReceiptVoucherDialog(props){
    const {open, onClose,onSave ,invData } = props;

    const url = uri+'Res';

    const {object:AutoOptions, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, requestData);
    const {data, fetching, fetchURL: fetchURL1} = useFetchURL({...{
      url: accountsURL,
      mapFunction: getReFormatingAccount,
    }})
    useEffect(()=>{
      console.log('the data is', data);
    })
      if(fetchinData || !data || fetching || !AutoOptions.Stocks)
        return <BackdropScI />


    return (
        <Dialog fullScreen open={open} onClose={onClose}>
         <IReceiptVoucher
        {...{
            AutoOptions: AutoOptions?.Employees? {...AutoOptions, Customers: data}: [],
            fetchURL,
            prmis: AutoOptions?.permisElm?? insh.permiElm, 
            ...props,
            invData,
            isDialog: false,
            isDialogData: true,
        }}/>
        </Dialog> 

    )
}




   